<template>
<div>
    <!-- Top Domain Details -->
    <div class="vx-row">
        <div class="vx-col w-full">
            <div class="domain-info mb-8">
                <!-- Domain -->
                <select-filter labelname="Domain" :options="domainOptions" optionsName="domainName" :value="domainSelected" @input="
              newDomain => {
                domainSelected = newDomain;
              }
            "></select-filter>
            <!-- Date Picker -->
                <date-range-picker :fromDateValue="fromDate" :toDateValue="toDate" @inputFromDate="
              newFromDate => {
                fromDate = newFromDate;
              }
            " @inputToDate="
              newToDate => {
                toDate = newToDate;
              }
            "></date-range-picker>
            <!-- Only Companies checkbox -->
                <only-company :onlyCompaniesValue="onlyCompanies" @input="
              newonlyCompanies => {
                onlyCompanies = newonlyCompanies;
              }
            "></only-company>
            <!-- Update -->
                <vs-button class="bg-actionbutton small-btn" type="filled" @click="updateData()">{{ LabelConstant.buttonLabelUpdate }}</vs-button>
            </div>
        </div>
    </div>
    <div class="vx-row">
        <!-- Direct Vs Referral Visitors -->
        <div class="vx-col w-full mb-8 lg:w-2/3">
            <two-xaxis-chart
                :chartTitle="'Direct vs Referral Visitors'"
                :chartType="'line'"
                :chartHeight="'350'"
                :seriesNameOne="'Referral Visitors'"
                :seriesDataOne="directVSReferralVisitorsTimelineChartSeriesDataOne"
                :seriesNameTwo="'Direct Views'"
                :seriesDataTwo="directVSReferralVisitorsTimelineChartSeriesDataTwo"
                :optionsXaxis="directVSReferralVisitorsTimelineChartOptionsXaxis"
            ></two-xaxis-chart>
        </div>
        <!-- Direct Vs Referral Visitors -->
        <div class="vx-col w-full lg:w-4/12 mb-8">
            <two-column-chart
                :chartTitle="'Direct vs Referral Visitors'"
                :chartType="'bar'"
                :chartHeight="'350'"

                :seriesNameOne="'Referral'"
                :seriesDataOne="ReferralDirectVisitorsChartSeriesDataOne"
                
                :seriesNameTwo="'Direct'"
                :seriesDataTwo="ReferralDirectVisitorsChartSeriesDataTwo"
                
                :optionsXaxis="ReferralDirectVisitorsChartOptionsXaxis"
            ></two-column-chart>
        </div>
    </div>
    <div class="vx-row">
        <!-- Referral Sources -->
        <div class="vx-col w-full mb-8 lg:w-1/2">
            <three-column-table
                :refValue="'tableDataReferralSources'"
                @refDataInput="(newRefData) => {refReferralSourcesData = newRefData;}"
                :tableTitle="'Referral Sources'"
                :tableRecords="referralSources"
                :maxRecordPerPage="maxRecordPerPage"
                :columnNameArray="['Referral Source','Unique Visitors','%']"
                :columnSortKeyArray="['StatValueName', 'StatValue', 'StatPercentValue']"
                @clickToGetDetailData="getDetailedData"
                :getDetailedDataType="'Visitor_Search_Engine_Lower'"
            ></three-column-table>
        </div>
        <!-- Keywords -->
        <div class="vx-col w-full mb-8 lg:w-1/2">
            <three-column-table
                :refValue="'tableDataKeywords'"
                @refDataInput="(newRefData) => {refKeywordsData = newRefData;}"
                :tableTitle="'Keywords'"
                :tableRecords="keywordsReferrer"
                :maxRecordPerPage="maxRecordPerPage"
                :columnNameArray="['Keywords','Unique Visitors','%']"
                :columnSortKeyArray="['StatValueName', 'StatValue', 'StatPercentValue']"
                @clickToGetDetailData="getDetailedData"
                :getDetailedDataType="'Visitor_Search_Term_Lower'"
            ></three-column-table>
        </div>
    </div>
    <div class="vx-row">
        <!-- Social Media Referrals -->
        <div class="vx-col w-full mb-8 lg:w-1/2">
            <single-type-multi-column-chart
                :chartTitle="'Social Media Referrals'"
                :chartType="'bar'"
                :chartHeight="'325'"

                :seriesNameOne="'Unique Visitors'"
                :seriesDataOne="mediareferralsChartSeriesDataOne"

                :optionsXaxis="mediareferralsChartOptionsXaxis"
            ></single-type-multi-column-chart>
        </div>
        <!-- Social Media Referrals -->
        <div class="vx-col w-full mb-8 lg:w-1/2">
            <three-column-table
                :refValue="'tableDataSocialMediaReferrals'"
                @refDataInput="(newRefData) => {refSocialMediaReferralsData = newRefData;}"
                :tableTitle="'Social Media Referrals'"
                :tableRecords="socialMediaReferrer"
                :maxRecordPerPage="maxRecordPerPage"
                :columnNameArray="['Social Media Source','Unique Visitors','%']"
                :columnSortKeyArray="['StatValueName', 'StatValue', 'StatPercentValue']"
                @clickToGetDetailData="getDetailedData"
                :getDetailedDataType="'socialmedia'"
            ></three-column-table>
        </div>
    </div>

    <!-- Call Update Modal -->
    <analytics-view-information-modal
        :popupTitle="'Analytics - ' + category + ' : ' + subCategory"
        :isOpen="analyticsActive"
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :ISPIncludeMessage="ISPIncludeMessage"
        :zoneName="zoneName"
        
        :singleCardOneIcon="'UserIcon'"
        :singleCardOneSmallTitle="'Unique Visitors'"
        :singleCardOneBottomText="'From ' +analyticsUniqueVisitors.startValue +' to ' +analyticsUniqueVisitors.endValue +' unique visitors'"
        :singleCardOneUpDownValue="analyticsUniqueVisitors.percentage"
        :singleCardOneUpicon="'ArrowDownIcon'"
        :singleCardOneBgcolor="'bg-first-gradient'"
        :singleCardOneMainTextColor="'text-first'"
        :singleCardOneTextColor="'text-secondary'"
        :singleCardOneBGImg="'user.svg'"

        :singleCardTwoIcon="'EyeIcon'"
        :singleCardTwoSmallTitle="'Page Views'"
        :singleCardTwoBottomText="'From '+ pageViews.startValue +' to '+ pageViews.endValue +' Page Views'"
        :singleCardTwoUpDownValue="pageViews.percentage"
        :singleCardTwoUpicon="'ArrowDownIcon'"
        :singleCardTwoBgcolor="'bg-second-gradient'"
        :singleCardTwoMainTextColor="'text-second'"
        :singleCardTwoTextColor="'text-danger'"
        :singleCardTwoBGImg="'eye.png'"

        :chartTitle="'Changes Over this Period'"
        :chartType="'line'"
        :chartHeight="'350'"
        :seriesNameOne="'Unique Visitors'"
        :seriesDataOne="lineAreaChartSplineSeriesDataOne"
        :seriesNameTwo="'Page Views'"
        :seriesDataTwo="lineAreaChartSplineSeriesDataTwo"
        :optionsXaxis="lineAreaChartSplineOptionsXaxis"

        @clickToClosePopup="analyticsActive = false"
    ></analytics-view-information-modal>

    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
/* Filter */
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import OnlyCompany from "../../components/filter/OnlyCompany";
import HelpModal from "../../components/modal/HelpModal";
import ThreeColumnTable from '../../components/analytics/ThreeColumnTable.vue';
import AnalyticsViewInformationModal from '../../components/modal/AnalyticsViewInformationModal.vue';
/* chart */
import TwoColumnChart from '../../components/charts/TwoColumnChart.vue';
import SingleTypeMultiColumnChart from '../../components/charts/SingleTypeMultiColumnChart.vue';
import TwoXaxisChart from '../../components/charts/TwoXaxisChart.vue';

export default {
    components: {
        VxCard,
        SelectFilter,
        DateRangePicker,
        OnlyCompany,
        HelpModal,
        ThreeColumnTable,
        AnalyticsViewInformationModal,
        TwoXaxisChart,
        TwoColumnChart,
        SingleTypeMultiColumnChart
    },
    data() {
        return {
            refReferralSourcesData: null,
            refKeywordsData: null,
            refSocialMediaReferralsData: null,
            /* Domain Filter */
            domainSelected: {
                domainName: "All"
            },
            //Domain dropdown options
            domainOptions: [],
            //From Date
            fromDate: null,
            //To Date
            toDate: null,
            //Only Companies
            onlyCompanies: false,
            /* Analytics Popup  */
            analyticsActive: false,
            ISPIncludeMessage: "",
            dateStart: null,
            dateEnd: null,
            zoneName: null,
            category: null,
            subCategory: null,
            analyticsUniqueVisitors: {
                name: null,
                startValue: 0,
                endValue: 0,
                percentage: null
            },
            pageViews: {
                name: null,
                percentage: null,
                startValue: 0,
                endValue: 0
            },
            popupActive: false,

            maxRecordPerPage: 5,

            /* Mixed Chart */
            directVSReferralVisitorsTimelineChartOptionsXaxis: [],
            directVSReferralVisitorsTimelineChartSeriesDataOne: [],
            directVSReferralVisitorsTimelineChartSeriesDataTwo: [],

            /* Direct Vs Referral Visitors */
            ReferralDirectVisitorsChartSeriesDataOne: [],
            ReferralDirectVisitorsChartSeriesDataTwo: [],
            ReferralDirectVisitorsChartOptionsXaxis: [],
            
            //Social Media Referrals
            mediareferralsChartSeriesDataOne: [],
            mediareferralsChartOptionsXaxis: [],
            
            /* Table */
            referralSources: [],
            keywordsReferrer: [],
            socialMediaReferrer: [],
            /* Analytics popup Chart */
            lineAreaChartSplineSeriesDataOne: [],
            lineAreaChartSplineSeriesDataTwo: [],
            lineAreaChartSplineOptionsXaxis: [],
        };
    },
    created() {
        this.setFromDate();
        this.setToDate();
        this.getZoneListForFilter();
        this.getReferrerSources();
        this.getKeywords();
        this.getSocialMeadiaReferrer();
        this.getDirectReferralVisitorsGraphData();
        this.getSocialMediaChartData();
    },

    methods: {
        closepopup() {
            this.popupActive = false;
        },
        updateData() {
            this.refReferralSourcesData.tableDataReferralSources.currentx = 1
            this.refKeywordsData.tableDataKeywords.currentx = 1
            this.refSocialMediaReferralsData.tableDataSocialMediaReferrals.currentx = 1
            this.refReferralSourcesData.tableDataReferralSources.searchx = ""
            this.refKeywordsData.tableDataKeywords.searchx = ""
            this.refSocialMediaReferralsData.tableDataSocialMediaReferrals.searchx = ""
            this.getReferrerSources();
            this.getKeywords();
            this.getSocialMeadiaReferrer();
            this.getDirectReferralVisitorsGraphData();
            this.getSocialMediaChartData();
        },
        //Get Domain
        async getZoneListForFilter() {
            this.$vs.loading();
            await this.axios
                .get("/ws/Zone/GetZoneListForFilter")
                .then(response => {
                    let data = response.data;
                    for (let i = 0; i < data.length; i++) {
                        this.domainOptions.push({
                            domainName: data[i]
                        });
                    }
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get the referrer sources data between the dates 
        async getReferrerSources() {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsReferrer/GetReferralSourceStats", input)
                .then(response => {
                    let data = response.data;
                    this.referralSources = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get data of keywords 
        async getKeywords() {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsReferrer/GetKeywordStats", input)
                .then(response => {
                    let data = response.data;
                    this.keywordsReferrer = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get social media data
        async getSocialMeadiaReferrer() {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsReferrer/GetSocialMediaStats", input)
                .then(response => {
                    let data = response.data;
                    this.socialMediaReferrer = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // graph data
        async getDirectReferralVisitorsGraphData() {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsReferrer/GetReferralVsDirectData", input)
                .then(response => {
                    let data = response.data;

                    //this.socialMediaReferrer = data;
                    let x = [];
                    let DirectVisitors = [];
                    let ReferralVisitors = [];

                    data.TimeLineChartData.forEach(element => {
                        x.push(element.x);
                        DirectVisitors.push(element.Direct_Visitors);
                        ReferralVisitors.push(element.Referral_Visitors);
                    });
                    this.directVSReferralVisitorsTimelineChartOptionsXaxis = x
                    this.directVSReferralVisitorsTimelineChartSeriesDataOne = DirectVisitors
                    this.directVSReferralVisitorsTimelineChartSeriesDataTwo = ReferralVisitors
                    
                    let directReferralX = [];
                    let direct = [];
                    let referral = [];

                    if(data.ReferralvsDirectVisitors){
                        data.ReferralvsDirectVisitors.forEach(el => {
                            directReferralX.push(el.x);
                            direct.push(el.SecondGroup);
                            referral.push(el.FirstGroup);
                        });
                    }

                    this.ReferralDirectVisitorsChartSeriesDataOne = referral
                    this.ReferralDirectVisitorsChartSeriesDataTwo = direct
                    this.ReferralDirectVisitorsChartOptionsXaxis = directReferralX
                    
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // social media chart data
        async getSocialMediaChartData() {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsReferrer/GetSocialMediaChartData", input)
                .then(response => {
                    let data = response.data;
                    let x = [];
                    let v = [];
                    data.forEach(element => {
                        x.push(element.Key);
                        v.push(element.Value);
                    });
                    
                    this.mediareferralsChartSeriesDataOne = v
                    this.mediareferralsChartOptionsXaxis = x
                    
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get detailed data 
        async getDetailedData(category, subcategory) {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies,
                Category: category,
                SubCategory: subcategory
            };
            await this.axios
                .post("/ws/AnalyticsDetail/GetDetailData", input)
                .then(response => {
                    let data = response.data;
                    this.pageViews.name = data.Page_Views.StatName;
                    this.pageViews.startValue = data.Page_Views.StatStartValue;
                    this.pageViews.endValue = data.Page_Views.StatEndValue;
                    this.pageViews.percentage = data.Page_Views.StatChangePercent;

                    this.analyticsUniqueVisitors.name = data.Unique_Visitors.StatName;
                    this.analyticsUniqueVisitors.startValue = data.Unique_Visitors.StatStartValue;
                    this.analyticsUniqueVisitors.endValue = data.Unique_Visitors.StatEndValue;
                    this.analyticsUniqueVisitors.percentage = data.Unique_Visitors.StatChangePercent;

                    this.dateStart = data.Date_Start;
                    this.dateEnd = data.Date_End;
                    this.zoneName = data.Zone_Name;
                    this.category = data.Category;
                    this.subCategory = data.SubCategory;
                    this.ISPIncludeMessage = data.ISP_Include_Message;
                    this.analyticsActive = true;

                    let x = [];
                    let FirstGroup = [];
                    let secondGroup = [];

                    data.Default_Chart.forEach(element => {
                        x.push(element.x);
                        FirstGroup.push(element.FirstGroup);
                        secondGroup.push(element.SecondGroup);
                    });

                    this.lineAreaChartSplineSeriesDataOne = FirstGroup
                    this.lineAreaChartSplineSeriesDataTwo = secondGroup
                    this.lineAreaChartSplineOptionsXaxis = x

                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        }
    }
};
</script>
