<template>
    <vx-card class="h-full">
        <div class="card-title">
            <h2>{{tableTitle}}</h2>
        </div>
        <vs-table :ref="refValue" :data="tableRecords" :max-items="maxRecordPerPage" pagination>
            <template slot="thead">
                <vs-th></vs-th>            
                <vs-th v-for="(columnName, index) in columnNameArray" :key="index" :sort-key="columnSortKeyArray[index]">{{columnName}}</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td width="50">
                        <vs-button :title="LabelConstant.buttonLabelViewInformation" @click="$emit('clickToGetDetailData', getDetailedDataType, tr.StatValueName)" type="filled" icon-pack="feather" icon="icon-search" class="mr-0" color="primary"></vs-button>
                    </vs-td>
                    <vs-td :data="tr.StatValueName">
                        {{tr.StatValueName}}
                    </vs-td>
                    <vs-td :data="tr.StatValue">
                        {{tr.StatValue}}
                    </vs-td>
                    <vs-td :data="tr.StatPercent">
                        {{tr.StatPercent}}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </vx-card>
</template>

<script>

export default ({
    mounted(){
        this.$emit('refDataInput', this.$refs)
    },
    props:{
        tableTitle:{
            required: true
        },
        tableRecords:{
            required: true
        },
        maxRecordPerPage:{
            required: true
        },
        columnNameArray:{
            required: true
        },
        columnSortKeyArray:{
            required: true
        },
        getDetailedDataType:{
            required: true
        },
        refValue:{
            required: true
        }
    }
})
</script>
