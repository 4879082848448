<template>
    <vs-popup class="analytics-modal popup-width  close-open" :title="popupTitle" :active.sync="isOpen">
        <vs-button
            @click="$emit('clickToClosePopup')"
            color="secondary"
            type="filled"
            class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}</vs-button>
        <div class="vx-row">
            <div class="vx-col w-full mb-8">
                <ul class="analytics-time-list">
                    <li>
                        <feather-icon icon="CalendarIcon"></feather-icon>
                        <h3>Dates</h3>
                        <span class="small-dis">{{ dateStart }} to {{ dateEnd }}</span>
                    </li>
                    <li>
                        <feather-icon icon="CloudIcon"></feather-icon>
                        <h3>{{ ISPIncludeMessage }}</h3>
                    </li>
                    <li>
                        <feather-icon icon="CheckIcon"></feather-icon>
                        <h3>Zone</h3>
                        <span class="small-dis">{{ zoneName }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mb-8">
                <single-card 
                    :icon="singleCardOneIcon" 
                    :smallTitle="singleCardOneSmallTitle" 
                    :bottomText="singleCardOneBottomText" 
                    :upDownValue="singleCardOneUpDownValue" 
                    :Upicon="singleCardOneUpicon" 
                    :bgcolor="singleCardOneBgcolor" 
                    :maintextcolor="singleCardOneMainTextColor" 
                    :textcolor="singleCardOneTextColor" 
                    :bgimg="singleCardOneBGImg"
                ></single-card>
            </div>
            <div class="vx-col md:w-1/2 w-full mb-8">
                <single-card 
                    :icon="singleCardTwoIcon" 
                    :smallTitle="singleCardTwoSmallTitle" 
                    :bottomText="singleCardTwoBottomText" 
                    :upDownValue="singleCardTwoUpDownValue" 
                    :Upicon="singleCardTwoUpicon" 
                    :bgcolor="singleCardTwoBgcolor" 
                    :maintextcolor="singleCardTwoMainTextColor" 
                    :textcolor="singleCardTwoTextColor" 
                    :bgimg="singleCardTwoBGImg"
                ></single-card>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full">
                <two-xaxis-chart
                    :chartTitle="chartTitle"
                    :chartType="chartType"
                    :chartHeight="chartHeight"
                    :seriesNameOne="seriesNameOne"
                    :seriesDataOne="seriesDataOne"
                    :seriesNameTwo="seriesNameTwo"
                    :seriesDataTwo="seriesDataTwo"
                    :optionsXaxis="optionsXaxis"
                ></two-xaxis-chart>
            </div>
        </div>
        <div class="float-right mt-6 mb-8">
            <vs-button @click="$emit('clickToClosePopup')" color="grey" class="grey-btn" type="border">{{ LabelConstant.buttonLabelClose }}</vs-button>
        </div>
    </vs-popup>
</template>

<script>
import SingleCard from "../../components/SingleCard";
// import VueApexCharts from "vue-apexcharts";
import TwoXaxisChart from '../../components/charts/TwoXaxisChart.vue';

export default {
    components: {
        // VueApexCharts,
        SingleCard,
        TwoXaxisChart
    },
    props:{
        popupTitle:{
            required: true
        },
        isOpen:{
            required: true
        },
        dateStart:{
            required: true
        },
        dateEnd:{
            required: true
        },
        ISPIncludeMessage:{
            required: true
        },
        zoneName:{
            required: true
        },
        singleCardOneIcon:{
            required: true
        },
        singleCardOneSmallTitle:{
            required: true
        },
        singleCardOneBottomText:{
            required: true
        },
        singleCardOneUpDownValue:{
            required: true
        },
        singleCardOneUpicon:{
            required: true
        },
        singleCardOneBgcolor:{
            required: true
        },
        singleCardOneMainTextColor:{
            required: true
        },
        singleCardOneTextColor:{
            required: true
        },
        singleCardOneBGImg:{
            required: true
        },
        singleCardTwoIcon:{
            required: true
        },
        singleCardTwoSmallTitle:{
            required: true
        },
        singleCardTwoBottomText:{
            required: true
        },
        singleCardTwoUpDownValue:{
            required: true
        },
        singleCardTwoUpicon:{
            required: true
        },
        singleCardTwoBgcolor:{
            required: true
        },
        singleCardTwoMainTextColor:{
            required: true
        },
        singleCardTwoTextColor:{
            required: true
        },
        singleCardTwoBGImg:{
            required: true
        },
        chartTitle:{
            required: true
        },
        chartType:{
            required: true
        },
        chartHeight:{
            required: true
        },
        seriesNameOne:{
            required: true
        },
        seriesDataOne:{
            required: true
        },
        seriesNameTwo:{
            required: true
        },
        seriesDataTwo:{
            required: true
        },
        optionsXaxis:{
            required: true
        }
    }
}
</script>