<template>
    <div class="h-full">
        <vx-card class="single-card h-full">
            <div class="flex flex-column flex-wrap height-100 block-card h-full">
                <div class="main-title">
                    <div class="left-icon" :class="bgcolor"><feather-icon :icon="icon" class="icon-style vs-radius"></feather-icon></div>
                    <h2>
                        <span class="small-title">{{smallTitle}}</span> 
                        <span :class="maintextcolor">{{totalValue}}</span>
                        <span class="bottom-value">{{bottomText}}</span> 
                        <router-link v-if="viewAllLink" class="view-option text-actionbutton" :to="{name: viewAllLink, params: { uid: uid }}">View All</router-link>
                        <a href="#" v-if="emitTagChange" class="view-option text-actionbutton" @click="emitEventTageChange">View All</a>
                    </h2>                    
                    <div class="card-img"><img :src="require('@/assets/images/'+bgimg)"></div>                    
                </div>                
                <div class="up-down-value">
                    <span :class="textColorUpDown(totalValue, startValue, upDownValue, positiveNegative)"><feather-icon :icon="iconUpDown(totalValue, startValue, upDownValue, positiveNegative)" :class="textColorUpDown(totalValue, startValue, upDownValue, positiveNegative)"></feather-icon> {{upDownValue}} </span>
                </div>                
            </div>             
        </vx-card>
    </div>
</template>
<script>
import VxCard from "@/components/vx-card/VxCard";

export default {  
    props: ['icon', 'smallTitle', 'totalValue', 'bottomText',  'totalProgress', 'upDownValue', 'textcolor', 'bgcolor','Upicon', 'maintextcolor', 'bgimg', 'positiveNegative', 'startValue', 'viewAllLink','emitTagChange'],
    components: {
       VxCard
    },
    data() {
        return {
            uid: this.$route.params.id,    
        }
    },
    methods: {
        emitEventTageChange(){
            this.$emit('changeTab',this.emitTagChange);
        },
        iconUpDown(value1, value2, value, positiveNegative = false) {
            if(value){
                if(!positiveNegative){
                    if(value.substring(0,2) > 0){
                        return 'ArrowUpIcon';
                    }
                    else
                    {
                        if(value == '--' || value1 == value2){
                            return '';
                        }else{
                            return 'ArrowDownIcon';
                        }
                    }
                }else{
                    if(value.substring(0,2) > 0){
                        if(value == '--' || value1 == value2){
                            return '';
                        }else{
                            return 'ArrowDownIcon';
                        }
                    }
                    else
                    {
                        if(value1 == value2){
                            return '';
                        }else{
                            return 'ArrowUpIcon';
                        }
                    }
                }
            } 
        },
        textColorUpDown(value1, value2, value, positiveNegative = false) {
            if(value){
                if(!positiveNegative){
                    if(value.substring(0,2) > 0){
                        return 'text-secondary';
                    }
                    else
                    {
                        if(value == '--' || value1 == value2){
                            return 'text-gray';
                        }else{
                            return 'text-danger';
                        }
                    }
                }else{
                    if(value.substring(0,2) > 0){
                        if(value == '--' || value1 == value2){
                            return 'text-gray';
                        }else{
                            return 'text-danger';
                        }
                    }
                    else
                    {
                        if(value1 == value2){
                            return 'text-gray';
                        }else{
                            return 'text-secondary';
                        }
                    }
                }
            } 
        }
    },
}
</script>