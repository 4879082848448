<template>
    <vs-popup class="main-modal" title="Help - Dashboard" :active.sync="popupActive">
        <iframe src="https://player.vimeo.com/video/156472775?player_id=video2&amp;app_id=122963" height="310" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="" title="Main Dashboard"></iframe>
        <vs-button @click="closepopup" color="grey" type="border" class=" float-right mt-4 mb-8 grey-btn" >{{ LabelConstant.buttonLabelClose }}</vs-button>
    </vs-popup>
</template>
 
<script>
export default {  
    props:['popupActive'],
    data() {
        return {
            
        }
    },
    methods: {
        closepopup(){
            this.$emit("close")
        }
    },
}
</script>