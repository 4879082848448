<template>
    <vx-card class="h-full">
        <div class="card-title">
            <h2>{{chartTitle}}</h2>
            <!-- <vs-button
                @click="popupActive = true"
                color="gray"
                type="flat"
                icon-pack="feather"
                icon="icon-help-circle"
                class="p-0 help-btn"
            ></vs-button> -->
        </div>
        <vue-apex-charts :type="chartType" :height="chartHeight" :options="topBrowserChart.chartOptions" :series="topBrowserChart.series"></vue-apex-charts>
    </vx-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        VueApexCharts,
    },
    props:{
        chartTitle:{
            required: true
        },
        chartType:{
            required: true
        },
        chartHeight:{
            required: true
        },
        seriesNameOne:{
            required: true
        },
        seriesDataOne:{
            required: true
        },
        optionsXaxis:{
            required: true
        }
    },
    watch : {
        seriesDataOne : function () {
            this.topBrowserChart.series = [{
                    name: this.seriesNameOne,
                    data: this.seriesDataOne
                }
            ];
            this.topBrowserChart.chartOptions = {
                ...this.topBrowserChart.chartOptions,
                ...{
                    xaxis: {
                        categories: this.optionsXaxis,
                    }
                }
            };
        }
    },
    data (){
        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        return {
            topBrowserChart: {
                series: [
                    {
                        name: this.seriesNameOne,
                        data: this.seriesDataOne
                    }
                ],
                chartOptions: {
                    colors: [token[uid].themeDetail.tempChartColor1],
                    plotOptions: {
                        bar: {
                            endingShape: "rounded",
                            columnWidth: "45%"
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ["transparent"]
                    },
                    xaxis: {
                        categories: []
                    },
                    yaxis:{
                        labels: {
                            formatter: function (val) {
                                return parseInt(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            },
                        },
                    },
                    grid: {
                        show: true,
                        borderColor: "#f2f2f2",
                        strokeDashArray: 3,
                        position: "back",
                        xaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            },
                        },
                    },
                }
            },
        }
    }    
}
</script>