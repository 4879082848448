 <template>
    <div class="only-company">
        <vs-checkbox v-model="onlyCompanies">Only Companies</vs-checkbox>
    </div>
 </template>
 <script>
export default {  
    props:{
        onlyCompaniesValue:{
            type: Boolean,
            required: true
        }, 
    },
   data() {
        return {
            
        }
    },
    // watch: {
    //     onlyCompaniesValue() {
    //         this.$emit('input', this.onlyCompaniesValue);
    //     },
    // },
    computed: {
        onlyCompanies: {
            get() { return this.onlyCompaniesValue },
            set(onlyCompaniesValue) {this.$emit('input', onlyCompaniesValue)}
        }
    },
}
</script>
