 <template>
    <div class="domain-select">
        <h6>{{labelname}}</h6>
        <v-select :label="optionsName" :options="options" v-model="domain" :clearable="false"/>
    </div>
 </template>

<script>
import vSelect from 'vue-select'
export default {  
    components: {    
        vSelect
    },
    props:{labelname:String, 
            options:{
                type: Array,
                required: true
            },
            optionsName:String, 
            value: {
                type: Object,
                required: true
            }
    },
    // watch: {
    //         value() {
    //             this.$emit('input', this.value);
    //         }
    // },
    computed: {
        domain: {
            get() { return this.value },
            set(value) {this.$emit('input', value)}
        }
    },
    data() {
        return {
            
        }
    }
}
</script>